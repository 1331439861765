// react states
import { useState, useEffect } from 'react';
// firebase database
import { db } from '../../Firebase/firebase';
import { query, getDocs, collection, orderBy, limit, startAfter } from 'firebase/firestore';
// Utility function
import { matchStrings } from '../../Components/SearchpageComponents/SearchingFunction/searchFunctionality';

export const useMarketplaceSearch = ()=>{
    const [searching, setSearching] = useState(false);
    const [productList, setProductList] = useState([]);
    const [searchError, setSearchError] = useState(null);

    // Utility function to fetch batch of products.
    const fetchBatch = async (startAfterDoc = null) => {
        const docsRef = collection(db, "Products");
        let q = query(docsRef, orderBy("name"), limit(250));
      
        if (startAfterDoc) {
          q = query(docsRef, orderBy("name"), startAfter(startAfterDoc), limit(250));
        }
      
        const snapshot = await getDocs(q);
        return { docs: snapshot.docs.map(doc => doc.data()), lastDoc: snapshot.docs[snapshot.docs.length - 1] };
    };

    // Utility function to fetch all the batches
    const fetchAllDocuments = async () => {
        const uniqueDocs = new Map(); // Use Map to maintain insertion order while ensuring uniqueness

        let firstBatch = await fetchBatch();
        firstBatch.docs.forEach(doc => uniqueDocs.set(doc.name, doc));

        let secondBatch = await fetchBatch(firstBatch.lastDoc);
        secondBatch.docs.forEach(doc => uniqueDocs.set(doc.name, doc));

        let thirdBatch = await fetchBatch(secondBatch.lastDoc);
        thirdBatch.docs.forEach(doc => uniqueDocs.set(doc.name, doc));

        let fourthBatch = await fetchBatch(thirdBatch.lastDoc);
        fourthBatch.docs.forEach(doc => uniqueDocs.set(doc.name, doc));

        return Array.from(uniqueDocs.values());
      };

    const fetchAllProducts = async()=>{
        setSearching(true);
        const resultantProductList = await fetchAllDocuments();
        // console.log(resultantProductList);
        setProductList(resultantProductList);

        setSearching(false);
        setSearchError(null);
    }

    // FOR PERFORMING SEARCH WITH OR WITHOUT FILTERS
    const productTextSearch = async( textToBeSearched, countryFilter )=>{

        const tempProductList = await fetchAllDocuments();

        setSearching(true);
        setSearchError(null);

        let temporaryResultArray = [];
        
        if(tempProductList.length > 0){

            for(const product of tempProductList){
                const productName = product.name;
                if(matchStrings(productName, textToBeSearched)){
                    if(countryFilter.India === true){
                        if(product.country === 'India'){
                            temporaryResultArray.push(product);
                        }
                    }
                    if(countryFilter.UK === true){
                        if(product.country === 'UK'){
                            temporaryResultArray.push(product);
                        }
                    }
                }else{
                    continue;
                }
            }

            setProductList(temporaryResultArray);
        }else{
            setSearching(false);
            setSearchError('Cant find the product. Sorry for the inconvenience!')
            return [];
        }

        setSearching(false);
        setSearchError(null);
    }

    useEffect(()=>{
        fetchAllProducts();
    },[])

    return { searching, searchError, productList, productTextSearch 
        // parameterBasedProductSearching 
    };
}