/* eslint-disable react-hooks/exhaustive-deps */
// styles
import './search_page.css';
// react states
import { useState, useEffect } from 'react';
// firebase imports
import { db } from '../../Firebase/firebase';
import { collection, getDocs, query, orderBy } from "firebase/firestore";
// import { useSearch } from '../../Hooks/useSearch';

// custom componennts
import { matchStrings } from '../../Components/SearchpageComponents/SearchingFunction/searchFunctionality';
import { useGetAllUsers, allUsers } from '../../Hooks/useGetAllUsers';
import SearchGameBox from '../../Components/SearchpageComponents/search_game_box';
import SearchUserBox from '../../Components/SearchpageComponents/search_user_box';
import GameRequestDialog from '../../Components/GameRequestComponents/GameRequestDialogs/gameRequestDialog';
import { TextField } from '@mui/material';

export default function SearchPage(){

    const [searchText, setSearchText] = useState('');
    const [allGames, setAllGames] = useState([]);
    const [searchedArray, setSearchedArray] = useState([]);

    // For user search
    const [userSearchedArray, setUserSearchedArray] = useState([]);

    const { loadingUsers } = useGetAllUsers();
    useEffect(()=>{
        setUserSearchedArray(allUsers);
    },[allUsers])

    const handleSearchChange = (e)=>{
        e.preventDefault();
        setSearchText(e.target.value);
    }

    const getGames = async()=>{
        const queryGameSnapshot = await getDocs(query(collection(db, "Games"),orderBy('year_of_release','asc')));
        queryGameSnapshot.forEach((gameDoc) => {
            var gameObject = {
                "id":gameDoc.id,
                "cover":gameDoc.data().cover_image_url,
                "mainImg":gameDoc.data().thumbnail_image,
                ...gameDoc.data()
            };
        setAllGames(allGames => [...allGames, gameObject])
        });
    }

    // For retreiving all the games from the database, everytime the page loads.
    useEffect(()=>{
        setAllGames([]);
        setSearchedArray([]);
        getGames();
    },[]);

    // To toggle the filter change
    const [filter, setFilter] = useState(0);
    const handleFilterChange = (index)=>{
        setFilter(index);
    }

    // For searching everytime there is a change in the search term.
    useEffect(()=>{
        if(filter === 0){
            setSearchedArray([]);
            var searchedKeys = [];
            var searchedGames = [];
            if(searchText===''){
                setSearchedArray([]);
                searchedKeys = [];
                searchedGames = [];
            }
            for(var i=0;i<allGames.length;i++){
                var currentGame = allGames[i];
                if(searchText===''){
                    setSearchedArray([]);
                    searchedKeys = [];
                    searchedGames = [];
                }
                // if(currentGame.name.toUpperCase().includes(searchText.toUpperCase())){
                if(matchStrings(currentGame.name, searchText) === true){
                    if(searchedKeys.includes(currentGame.name.toUpperCase())){
                        continue;
                    }else{
                        searchedKeys.push(currentGame.name.toUpperCase());
                        // console.log("Adding the game to searched array is = ",currentGame.name);
                        searchedGames.push(currentGame);
                        // console.log('Game added to searched array ',searchedGames);
                    }
                }
            }
            setSearchedArray(searchedGames);
        }
        if(filter === 1){
            setUserSearchedArray([]);
            var searchedUserKeys = [];
            var searchedUsers = [];
            if(searchText===''){
                setUserSearchedArray([]);
                searchedUserKeys = [];
                searchedUsers = [];
            }
            for(var x=0;x<allUsers.length;x++){
                var currentUser = allUsers[x];
                if(searchText===''){
                    setUserSearchedArray([]);
                    searchedUserKeys = [];
                    searchedUsers = [];
                }
                // if(currentGame.name.toUpperCase().includes(searchText.toUpperCase())){
                if(matchStrings(currentUser.Name, searchText) === true){
                    if(searchedUserKeys.includes(currentUser.Name.toUpperCase())){
                        continue;
                    }else{
                        searchedUserKeys.push(currentUser.Name.toUpperCase());
                        // console.log("Adding the game to searched array is = ",currentGame.name);
                        searchedUsers.push(currentUser);
                        // console.log('Game added to searched array ',searchedGames);
                    }
                }
            }
            setUserSearchedArray(searchedUsers);
        }
    },[searchText, setSearchText]);

    return(
        <div className='search-page-container'>
            {/* FOR FUTURE VERSIONS */}
            <GameRequestDialog inputText={searchText} searchLength={searchedArray.length}/>

            {/* FOR FUTURE VERSIONS */}
            
            <div className="search-area">
                <TextField
                    autoFocus={true}
                    placeholder={'Search any games and users here...'}
                    variant="filled"
                    sx={{
                        width:'100%',
                        color: "#f8f8f8",
                        backgroundColor:'transparent',
                        "& .MuiInputLabel-root": { color: "#f8f8f8" }, // Label color
                        "& .MuiFilledInput-root": {
                          color: "#f8f8f8", // Text color
                          "&::before": { borderBottomColor: "#f8f8f8" }, // Default underline color
                          "&:hover::before": { borderBottomColor: "#f8f8f8" }, // Hover effect
                          "&::after": { borderBottomColor: "#f8f8f8" }, // Focused underline color
                        },
                      }}
                      value={searchText}
                      onChange={(e)=>handleSearchChange(e)}
                />
                <div className="search-filters">
                    <div className="search-filter-box"
                         onClick={()=>handleFilterChange(0)}
                    >
                        <div className={ filter === 0 ? "search-filter-option checked" : "search-filter-option"}>
                            <p className='search-filter-name'>Games</p>
                        </div>
                    </div>

                    <div className="search-filter-box"
                         onClick={()=>handleFilterChange(1)}
                    >
                        <div className={ filter === 1 ? "search-filter-option checked" : "search-filter-option"}>
                            <p className='search-filter-name'>Users</p>
                        </div>
                    </div>
                </div>
            </div>
            {
                filter === 0 && <SearchGameBox searchList={searchedArray}/>
            }
            {
                filter === 1 && <SearchUserBox loadingUsers={loadingUsers} searchUserList={userSearchedArray} />
            }
        </div>
    )
}