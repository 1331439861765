/* eslint-disable react-hooks/exhaustive-deps */
// styles
import { useEffect, useState } from 'react';
import './userGamesTile.css';
import { useFetchGameData } from '../../../Hooks/GameData/useFetchGameData';
// import UserGameOptions from './useGameOptions';
// mui components
import { Box, IconButton, LinearProgress, Typography, Menu, MenuItem, useMediaQuery, Dialog, DialogTitle, DialogContent, RadioGroup, FormControlLabel, Radio, Slider, DialogActions, Button, TextField } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
// import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { useRemoveFromProfile } from '../../../Hooks/GameUserInteraction/useRemoveFromProfile';
import { useNavigate } from 'react-router-dom';
import { useUserProfileUpdate } from '../../../Hooks/UserProfile/useUserProfileUpdate';

// To show the game completion progress
const CustomLinearProgressBar = styled(LinearProgress)(({ theme }) => ({
    "& .MuiLinearProgress-bar": {
    backgroundColor: "#00ADFF", // Custom progress bar color
    // backgroundColor:'#ffe74c'
  },
  backgroundColor: "#FFF9E0",
}));

export default function UserGameTile({ gameObj, 
                                        // gameID, 
                                        currentGameCollection, updateGameCollection,
                                        userProfileID, loggedInUserID,
                                        isCurrentlyPlaying
                                    }){
    
    // console.log({
    //     'gameObj': gameObj,
    //     // 'gameID': gameID
    // })

    // Just to get the orientation of the device
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // custom hook to get game details of a game
    const { fetching, fetchGameData, gameData } = useFetchGameData();

    // custom hook to do user profile update
    const { isUpdating, updatedGameList, updateProgressStatus, updateUserGameProgress,
        updatePlayingStatus, updateGameStatus, updateUserGameRank
     } = useUserProfileUpdate();

    useEffect(()=>{
        // if(gameID !== null && gameID !== undefined)
        //     fetchGameData(gameID);
        if(gameObj !== null && gameObj !== undefined)
            fetchGameData(gameObj.game_id);
    },[gameObj])

    // console.log(gameData, isCurrentlyPlaying);
    // const [openOptionsDialog, setOpenOptionsDialog] = useState(false);
    // const handleOpenOptionsDialog = ()=>{
    //     setOpenOptionsDialog(true);
    // }

    // Anchor for user game tile
    const [userGameTileAnchor, setUserGameTileAnchor] = useState(null);
    // For opening the user game tile options
    const handleUserGameTileOptionsOpen = (accountEvent)=>{
        setUserGameTileAnchor(accountEvent.currentTarget);
    }
    // To close the user game tile options
    const handleUserGameTileOptionsClose = ()=>{
        setUserGameTileAnchor(null);
    }

    // Hook for removing games from user collection
    const { removeFromUserCollection } = useRemoveFromProfile();
    const handleGameRemoval = ()=>{
        removeFromUserCollection({
            'id':gameObj.game_id,
            ...gameObj
        });
    }

    // To navigate user to gamepage
    const profileNav = useNavigate();
    const navigateToGamepage = ()=>{
        profileNav(`/game_page/${gameObj.game_id}`)
    }

    
    // FOR PROGRESS CHANGING DIALOG BOX FUNCTIONALITY.
    const marks = [{value:0, label:0},{value:10, label:10},{value:20, label:20},{value:30, label:30},{value:40, label:40},
        {value:50, label:50},{value:60, label:60},{value:70, label:70},{value:80, label:80},
        {value:90, label:90},{value:100, label:100}
    ]
    const [openGameProgressDialog, setOpenGameProgressDialog] = useState(false);
    const [statusSelected, setStatusSelected] = useState('playing');
    const handleOpenGameProgressDialog = ()=>{
        setOpenGameProgressDialog(true);
    }
    const handleCloseGameProgressDialog = ()=>{
        setOpenGameProgressDialog(false);
    }

    // TO SHOW PROGRESSBAR CHANGE WHEN I AM PLAYING IS CLICKED.
    const [showSlider, setShowSlider] = useState(false);
    useEffect(()=>{
        if(statusSelected === 'playing'){
            setShowSlider(true);
        }else{
            setShowSlider(false);
        }
    },[statusSelected])
    // FOR PROGRESS CHANGING DIALOG BOX FUNCTIONALITY.

    // To perform game progress update
    const [newProgress, setNewProgress] = useState((gameObj !== null && gameObj !== undefined) ? gameObj.progress : 0);
    const updateGameProgress = ()=>{
        if(gameObj !== null && gameObj !== undefined){
            if(statusSelected === 'playing'){
                updateUserGameProgress(gameObj, newProgress);
            }else{
                updateGameStatus(gameObj, statusSelected);
            }
        }
    }
    useEffect(()=>{
        if(updateProgressStatus === 'success' || updatePlayingStatus === 'success'){
            updateGameCollection(updatedGameList);
        }
    },[updateProgressStatus, updatePlayingStatus])

    // FOR CHANGING GAME RANK
    // const [newRank, setNewRank] = useState((gameObj !== null && gameObj !== undefined) ? gameObj.rank : 1);
    // const [changeGameRank, setChangeGameRank] = useState(false);
    // const handleOpenGameRankChangeDialog = ()=>{
    //     setChangeGameRank(true);
    // }
    // const handleCloseGameRankChangeDialog = ()=>{
    //     setChangeGameRank(false);
    // }

    // const handleNewRankInput = (rankEvent)=>{
    //     setNewRank(rankEvent.target.value)
    // }
    // FOR CHANGING GAME RANK
    

    return(
        <>
        {/* GAME PROGRESS DIALOG BOX */}
        <Dialog 
            open={openGameProgressDialog}
            onClose={handleCloseGameProgressDialog}
            maxWidth={'sm'}
            sx={{ 'fontFamily':'Paciencia' }}
        >
        {
            fetching === false && gameData !== null && gameData !== undefined &&
            <DialogTitle sx={{ 'backgroundColor':'#191917', color:'#f8f8f8' }}>{gameData.name}</DialogTitle>
        }
        <DialogContent
                       sx={{  backgroundColor:'#191817', 
                              color:'#f8f8f8', 
                              fontSize:'0.9rem', 
                              boxSizing:'border-box', 
                              padding:'5%',
                              display:'flex',
                              justifyContent:'center',
                              alignItems:'center',
                            }}
        >
            <RadioGroup sx={{ display:'flex', flexDirection:'column', gap:'10px' }} value={statusSelected}>
                <FormControlLabel value="playing" control={<Radio sx={{ color:'#f8f8f8' }}/>} 
                    label={<div className='radio-btn-div' style={{ height:'auto', display:'flex', flexDirection:'column' }}>
                            <p style={{ padding:'0', margin:'0', fontSize:'0.8rem', fontWeight:'bold' }}>Playing</p>
                            <p style={{ padding:'0', margin:'0', fontSize:'0.65rem', color:'#a0a0a0' }}>I am currently playing it.</p>
                           </div>
                        }
                    onClick={(e)=>setStatusSelected(e.target.value)}
                />

                {
                    showSlider === true &&
                    <Box sx={{ boxSizing:'border-box', margin:'5px', width:'95%' }}>
                        <Slider
                            aria-label="Restricted values"
                            defaultValue={0}
                            value={newProgress}
                            onChange={(e)=>setNewProgress(e.target.value)}
                            // getAriaValueText={valuetext}
                            step={null}
                            valueLabelDisplay="auto"
                            marks={marks}
                            sx={{
                                color: "#FFE74C", // Progress bar color
                                "& .MuiSlider-markLabel": {
                                    color: "#F8F8F8", // Mark label text color
                                },
                                "& .MuiSlider-markLabelActive": {
                                    color:'#FFE74C'
                                    // color: "#00FF00", // Active mark label text color
                                },
                            }}
                        />
                    </Box>
                }
                
                <FormControlLabel value="paused" control={<Radio sx={{ color:'#f8f8f8' }}/>} 
                    label={<div className='radio-btn-div' style={{ height:'auto', display:'flex', flexDirection:'column' }}>
                            <p style={{ padding:'0', margin:'0', fontSize:'0.8rem', fontWeight:'bold' }}>Paused</p>
                            <p style={{ padding:'0', margin:'0', fontSize:'0.65rem', color:'#a0a0a0' }}>I am not able to play it currently, but will resume in the future.</p>
                        </div>
                    }
                    onClick={(e)=>setStatusSelected(e.target.value)}
                />
                <FormControlLabel value="beat" control={<Radio sx={{ color:'#f8f8f8' }}/>} 
                    label={<div className='radio-btn-div' style={{ height:'auto', display:'flex', flexDirection:'column' }}>
                            <p style={{ padding:'0', margin:'0', fontSize:'0.8rem', fontWeight:'bold' }}>Completed</p>
                            <p style={{ padding:'0', margin:'0', fontSize:'0.65rem', color:'#a0a0a0' }}>I have completed it.</p>
                            </div>
                    }
                        onClick={(e)=>setStatusSelected(e.target.value)}
                />
                <FormControlLabel value="quit" control={<Radio sx={{ color:'#f8f8f8' }}/>}  
                    label={<div className='radio-btn-div' style={{ height:'auto', display:'flex', flexDirection:'column' }}>
                        <p style={{ padding:'0', margin:'0', fontSize:'0.8rem', fontWeight:'bold' }}>Quit</p>
                        <p style={{ padding:'0', margin:'0', fontSize:'0.65rem', color:'#a0a0a0' }}>I am not going to play it anymore.</p>
                        </div>
                    } 
                    onClick={(e)=>setStatusSelected(e.target.value)}
                />
            </RadioGroup>
        </DialogContent>
        <DialogActions sx={{ backgroundColor:'#191917', display:'flex', justifyContent:'center', alignItems:'center' }}>
            <Button variant='contained' sx={{ backgroundColor:'#FFE74C', color:'#080808', fontWeight:'500',
                                            "&:hover": {
                                            backgroundColor: "#FFD700", // Custom hover color
                                        }, }}
                    onClick={()=>updateGameProgress(gameObj, newProgress)}
            >
                    Save Changes
            </Button>
        </DialogActions>
        </Dialog>
        {/* GAME PROGRESS DIALOG BOX */}

        {/* GAME RANK CHANGE DIALOG */}
        {/* <Dialog
            open={changeGameRank}
            onClose={handleCloseGameRankChangeDialog}
            maxWidth={'sm'}
            fullWidth
        >
            {
                fetching === false && gameData !== null && gameData !== undefined &&
                <DialogTitle sx={{ 'backgroundColor':'#191917', color:'#f8f8f8' }}>{gameData.name}</DialogTitle>
            }
            <DialogContent sx={{ backgroundColor:'#191917', fontFamily:'Paciencia',
                display:'flex', justifyContent:'space-between', alignItems:'center' 
             }}>
                <div className="current-rank-box">
                    <p className='current-rank-heading'>Current Rank</p>
                    <p className='current-rank-text'>{gameObj.rank}</p>
                </div>
                <div className="change-icon-box">
                    <DoubleArrowIcon sx={{ color:'#F8F8F8', fontSize:'75px' }}/>
                </div>
                <div className="new-rank-box">
                    <p className='new-rank-heading'>New Rank</p>
                    <TextField variant='standard'
                        InputProps={{
                            disableUnderline: true, // Removes the underline
                            style: {
                                width:'150px',
                                color: '#f8f8f8', // Text color
                                borderBottom: '1px solid #ffffff', // Custom border color
                                fontSize:'2.5rem'
                            },
                        }}
                        sx={{
                            '& .MuiInput-underline:before': {
                                borderBottomColor: '#ffffff', // Default border color
                            },
                            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                borderBottomColor: '#ffffff', // Hover border color
                            },
                            '& .MuiInput-underline:after': {
                                borderBottomColor: '#ffffff', // Active border color
                            },
                        }}
                        onChange={(e) => handleNewRankInput(e)}
                    />
                </div>
            </DialogContent>
            <DialogActions sx={{ backgroundColor:'#191917', fontFamily:'Paciencia', display:'flex', justifyContent:'center', alignItems:'center' }}>
                <Button variant='contained' sx={{ backgroundColor:'#FFE74C', color:'#080808', fontWeight:'500',
                                                "&:hover": {
                                                backgroundColor: "#FFD700", // Custom hover color
                                            }, }}
                        onClick={()=>updateUserGameRank(gameObj, newRank)}
                >
                        Save Changes
                </Button>
            </DialogActions>
        </Dialog> */}
        {/* GAME RANK CHANGE DIALOG */}

        {/* {
            gameData !== null &&
                <UserGameOptions
                    gameID={gameID}
                    openOptions={openOptionsDialog}
                    setOpenOptions={setOpenOptionsDialog}
                    userProfileID={userProfileID}
                    loggedInUserID={loggedInUserID}
                    currentGameIsCP={isCurrentlyPlaying}
                    gameData={gameData}
                />
        } */}
        {
            fetching === false && gameData !== undefined && gameData !== null &&
            <Box className="user-game-tile">
                <div className="user-game-tile-img-container">
                    <img src={gameData.thumbnail_image} alt={gameData.name}/>
                </div>
                <div className="user-game-info-box">
                    <div className="user-game-info-options-and-heading">
                        <p className='user-game-info-box-game-name'>{gameData.name}</p>
                        <IconButton aria-label='More options' onClick={(e)=>handleUserGameTileOptionsOpen(e)}>
                            <MoreHorizIcon sx={{ color:'#f8f8f8' }}/>
                        </IconButton>
                    </div>
                    {gameObj.status === 'quit' && <p className='user-game-info-box-game-subtext'>Quit</p>}
                    {gameObj.status === 'beat' && <p className='user-game-info-box-game-subtext'>Completed</p>}
                    {gameObj.status === 'paused' && <p className='user-game-info-box-game-subtext'>Paused</p>}
                    {gameObj.status === 'playing' && <p className='user-game-info-box-game-subtext'>Playing</p>}
                    {/* {gameObj.status === 'playing' &&  */}
                        <Box sx={{ width:'100%'}}>
                            <CustomLinearProgressBar variant='determinate' value={gameObj.progress}/>
                            <div className="progress-percentage-div">
                                <p className='user-game-info-box-game-subtext'>{gameObj.progress}%</p>
                            </div>
                        </Box>
                    {/* } */}
                </div>
            </Box>
            // {
                // gameData !== undefined && gameData !== null &&
                // <div className="user-game-tile-image-container"
                //      onClick={()=>handleOpenOptionsDialog()}
                // >
                //     <img src={gameData.thumbnail_image} alt=""/>
                //     {
                //         isCurrentlyPlaying &&
                //         <div className="currently-playing-tag">
                //             Currently Playing
                //         </div>
                //     }
                // </div>  
            // }   
            // {/* </div> */}
        }
        {/* MENU OPTIONS FOR THE TILE */}
        <Menu
            anchorEl={userGameTileAnchor}
            open={Boolean(userGameTileAnchor)}
            onClose={handleUserGameTileOptionsClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            sx={{
                "& .MuiPaper-root": {
                  backgroundColor: "#171719",
                  color:'#f8f8f8',
                  padding:'0',
                  height:'auto',
                  width:!isMobile ? 'auto' :'45vw',
                  display:'flex',
                  flexDirection:'column',
                  gap:'25px',
                  justifyContent:'center',
                  alignItems:'center'
                }
            }}
        >
            <MenuItem sx={{ fontFamily:'Paciencia', fontSize:'0.9rem' }}
                      onClick={()=>navigateToGamepage()}
            >
                Go to Gamepage
            </MenuItem>
            {
                userProfileID === loggedInUserID &&
                <MenuItem sx={{ fontFamily:'Paciencia', fontSize:'0.9rem' }}
                          onClick={()=>handleOpenGameProgressDialog()}
                >
                    Change Progress
                </MenuItem>
            }
            {/* {
                userProfileID === loggedInUserID &&
                <MenuItem sx={{ fontFamily:'Paciencia', fontSize:'0.9rem', display:'flex', flexWrap:'wrap' }}
                          onClick={()=>handleOpenGameRankChangeDialog()}
                >
                    Change Position
                </MenuItem>
            } */}
            {
                userProfileID === loggedInUserID &&
                <MenuItem sx={{ fontFamily:'Paciencia', fontSize:'0.9rem', display:'flex', flexWrap:'wrap' }}
                      onClick={()=>handleGameRemoval()}
                >
                    Remove from Collection
                </MenuItem>
            }
        </Menu>
        {/* MENU OPTIONS FOR THE TILE */}
        </>
    )
}