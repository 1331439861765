// styles
import './productPage.css';
// react routing
import { useParams } from 'react-router-dom';
// Custom hooks
import { useFetchProductDetails } from '../../Hooks/Marketplace/useFetchProductDetails';
// mui components
import { Button } from '@mui/material';
// For SEO purposes
import { Helmet } from 'react-helmet-async';

export default function ProductPage(){

    const { product_id } = useParams();
    // console.log('productID => ',product_id);

    // Utility hook to obtain product data.
    const { fetching, productData } = useFetchProductDetails(product_id);
    // console.log('fetching', fetching);
    // console.log('productData => ', productData);

    const handleOpenAffiliateLinkPage = ()=>{
        window.open(productData.link, "_blank");
    }

    if(fetching === true){
        return (
            <div className="product-page-container">
                <p className='product-page-main-heading'>Loading your product...just a minute...</p>
            </div>
        );
    }

    if(fetching === false && productData !== null && productData !== undefined){
        return(
            <>
            <Helmet>
                <title>{productData.name} - Buy Now at Best Price</title>
                <meta property="og:title" content={productData.name} />
                <meta property="og:description" content={productData.console}/>
                <meta property="og:image" content={productData.image} />
                <meta property="og:url" content={`https://thegamerbroz.com/product/${product_id}`} />
                <meta name="robots" content="index, follow" />
                <script type="application/ld+json">
                    {JSON.stringify({
                    "@context": "https://schema.org/",
                    "@type": "Product",
                    "name": productData.name,
                    "image": productData.image,
                    "description": productData.console,
                    "brand": productData.console || "Unknown",
                    "offers": {
                        "@type": "Offer",
                        "url": `https://thegamerbroz.com/product/${product_id}`,
                        "priceCurrency": (productData.currency === 'INR') ? "INR": "GBP",
                        "price": productData.price || "N/A",
                    }
                    })}
                </script>
            </Helmet>
            
            {
                fetching === false && productData && productData !== null && productData !== undefined &&
                <div className="product-page-container">
                    <div className="product-image-container">
                        <div className="product-image-area">
                            <img src={productData.image} alt={productData.name}/>
                        </div>
                    </div>
                    <div className="product-details-container">
                        <p className='product-page-main-heading'>{productData.name}</p>
                        <p className='product-page-sub-heading'>Console: {productData.console}</p>
                        <p className='product-page-sub-heading'>Type: {productData.type}</p>
                        <p className='product-page-main-heading'>Price: {productData.currency === 'INR' ? 'Rs.' : '£' } {productData.price}</p>
                        <Button variant='contained' sx={{ backgroundColor:'#ffe74c', color:'#080808', fontFamily:'Paciencia', fontWeight:'600',
                                boxSizing:'border-box', marginTop:'10px',
                                "&:hover": {
                                    backgroundColor: "#FFD700"
                                }
                            }}
                            onClick={()=>handleOpenAffiliateLinkPage()}
                        >
                            BUY ON AMAZON
                        </Button>
                        <p className="product-page-sub-heading-2">{"Price Disclaimer: The prices displayed on our website are for reference only and may not reflect the actual price on the retailer’s site. Prices and availability are subject to change without notice. Final purchase decisions should be made based on the retailer’s listed price at the time of checkout."}</p>
                    </div>
                </div>
            }
            </>
        );
    }
}