/* eslint-disable react-hooks/exhaustive-deps */
// styles
import './userGamesCollection.css';
// react components
import { useEffect, useState } from 'react';
// react redux
import { useSelector } from 'react-redux';
import { selectUserData } from '../../Features/userPrivateDataSlice';
// custom components
import { useGetUserGameCollection } from '../../Hooks/UserProfile/useGetUserGameCollection';
import UserGameTile from './UserGamesComponents/userGamesTile';
// import UserGameSelectionDialog from './UserGamesComponents/userGameSelection';
// import AddGamesToCollection from './AddingGames/addGamesToCollection';

// mui components
// import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import { CircularProgress } from '@mui/material';
import { Helmet } from 'react-helmet';

export default function UserGamesCollection({ 
    userProfileID, loggedInUserID, setTotalGamesCounter, 
    setLikedGameCollection, userProfileData
    // likedGameCollection
 }){
    
    const loggedInUserData = useSelector(selectUserData);
    // console.log(loggedInUserData);

    const [ gameCollection, setGameCollection ] = useState([]);
    const [ userCurrentlyPlayingCollection, setUserCurrentlyPlaying ] = useState([]);

    // custom hook to just get the game collection of user if its not their profile
    const { fetchingCollection, userGameCollection, currentlyPlayingCollection,
            getGameCollection, reactToUserGameCollection, liking, likingStatus,
            gameCollectionLikesCount, likeBtnClicked
    } = useGetUserGameCollection(userProfileID);

    useEffect(()=>{
        if(userProfileID === loggedInUserID){
            if(JSON.stringify(loggedInUserData) !== '{}'){
                if(loggedInUserData.game_collection){
                    setTotalGamesCounter(loggedInUserData.game_collection.length);
                    var originalGameCollection = [...loggedInUserData.game_collection];
                    var sortedRankWiseGameCollection = originalGameCollection.sort((gameA, gameB) => gameB.progress - gameA.progress);
                    setGameCollection(sortedRankWiseGameCollection);
                }else{
                    setTotalGamesCounter(0);
                    setGameCollection([]);
                }

                if(loggedInUserData.currently_playing){
                    setUserCurrentlyPlaying(loggedInUserData.currently_playing);
                }else{
                    setUserCurrentlyPlaying([]);
                }
            }
        }else if(userProfileID !== loggedInUserID){
            // Get the game collection details of user here.
            getGameCollection(userProfileID);
        }
    },[userProfileID, loggedInUserID, loggedInUserData])

    useEffect(()=>{
        if(userGameCollection.length > 0){
            setGameCollection(userGameCollection);
            setTotalGamesCounter(userGameCollection.length);
        }
        if(currentlyPlayingCollection.length > 0){
            setUserCurrentlyPlaying(currentlyPlayingCollection);
        }
    },[userGameCollection, currentlyPlayingCollection]);

    // console.log('games => ',gameCollection);
    // console.log('cP games => ',userCurrentlyPlayingCollection);

    const [openAddGamePrompt, setOpenAddGamePrompt] = useState(false);

    // To like or dislike the game collection
    const reactToGameCollection = ()=>{
        reactToUserGameCollection(userProfileID);
    }

    useEffect(()=>{
        if(likingStatus === true && likeBtnClicked === true){
            setLikedGameCollection(true);
        }else{
            setLikedGameCollection(false);
        }
    },[likingStatus])

    console.log(gameCollection);

    return(
        <div className='user-games-collection'>
            {
                userProfileData !== null &&
                <Helmet>
                    <title>{`${userProfileData.Name} - Game Collection`}</title>
                    <meta name='description' content={`${userProfileData.Name} all games collection`}/>
                    <meta name='keywords' content={`${userProfileData.Name}, games, collection, games collection, all games, add games, like game collection`}/>
                </Helmet>
            }

            {/* <AddGamesToCollection 
                openDialog={setOpenAddGamePrompt}
                openGameCollection={openAddGamePrompt}
                collectionType={'allGames'}
                userCollectionArray={gameCollection}
            />  */}
            
            <div className="user-games-container">
            {
                fetchingCollection === false && 
                gameCollection.length > 0 &&
                gameCollection.map((game)=>(
                  <UserGameTile 
                        key={game.game_id}
                        gameObj = {game}
                        // gameID={game}
                        currentGameCollection = {gameCollection}
                        updateGameCollection = {setGameCollection}
                        userProfileID={userProfileID}
                        loggedInUserID={loggedInUserID}
                        isCurrentlyPlaying={ 
                            userCurrentlyPlayingCollection.includes(game) === true ? true : false
                        }
                  />  
                ))
            } 
            {
                fetchingCollection === false &&
                gameCollection.length === 0 &&
                <div className="no-user-games-prompt">
                {
                    userProfileID === loggedInUserID
                    && <p>You haven't added any games to your collection. Start doing it now.</p>
                }
                {
                    userProfileID !== loggedInUserID
                    && <p>{`No games added to collection yet.`}</p>
                }
                </div>
            }   
            </div>

            {/* Actions */}
        </div>
    )
}