// styles
import './productTile.css';
//mui components
import { Button } from '@mui/material';
// react router dom
import { useNavigate } from 'react-router-dom';

export default function ProductTile({ productObject }){

    const productNav = useNavigate();
    const navigateToShowMoreDetails = ()=>{
        productNav(`/product/${productObject.url}`)
    }

    const handleOpenAffiliatePage = ( productLink )=>{
        window.open(productObject.link, "_blank");
    }

    return(
        <div className="product-tile-container" key={productObject.url}>
            <div className="product-tile-img-container">
                <img src={productObject.image} alt={productObject.name}/>
            </div>
            <div className="product-tile-details">
                <p className='product-heading'>{productObject.name}</p>
                { Number.isNaN(productObject.price) === false && <p className='product-price-text'>{productObject.currency === 'INR' ? 'Rs.' : '£' } {productObject.price}</p> }
                { Number.isNaN(productObject.price) === true && <p className='product-unavailable-text'>Currently Unavailable</p> }
            </div>
            <div className="product-actions">
                <Button variant='contained' sx={{ backgroundColor:'#ffe74c', color:'#080808', fontFamily:'Paciencia', fontWeight:'600',
                        "&:hover": {
                            backgroundColor: "#FFD700"
                        }
                    }}
                    onClick={()=>handleOpenAffiliatePage()}
                >
                        BUY ON AMAZON
                </Button>
                {
                    Number.isNaN(productObject.price) === false && 
                    <Button variant='text' sx={{ color:'#FFE74C' }}
                        onClick={()=>navigateToShowMoreDetails()}
                    >
                        Show more details
                    </Button>
                }
            </div>
        </div>
    )
}