// react states
import { useState, useEffect } from 'react';
// react redux
import { useSelector } from 'react-redux';
import { selectUserData } from '../../Features/userPrivateDataSlice';
import { selectUser } from '../../Features/userSlice';
// Database
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../Firebase/firebase';

export const useUserProfileUpdate = ()=>{
    
    // To get the feedback of progress
    const [isUpdating, setIsUpdating] = useState(false);
    const [updateProgressStatus, setUpdateProgressStatus] = useState(null);
    const [updatePlayingStatus, setUpdatePlayingStatus] = useState(null);
    // const [updateRankStatus, setUpdateRankStatus] = useState(null);

    // To get the updated game list
    const [updatedGameList, setUpdatedGameList] = useState(null);

    // To get the current data of the user.
    const currentUserData = useSelector(selectUserData);
    const currentUserID = useSelector(selectUser)

    useEffect(()=>{
        if(JSON.stringify(currentUserData) !== '{}' && currentUserData !== null && currentUserData !== undefined){
            setUpdatedGameList(currentUserData.game_collection);
        }
    },[currentUserData])

    // 1. TO UPDATE PROFILE PICTURE OF THE USER
    // const updatingProfilePicture = async()=>{

    // }

    // 2. TO UPDATE COVER PICTURE OF THE USER
    // const updatingCoverPicture = async()=>{

    // }

    // 3. TO UPDATE THE PROGRESS OF THE GAME BY THE USER
    const updateUserGameProgress = async(gameObject, newProgress)=>{
        // console.log(currentUserData);
        if(JSON.stringify(currentUserData) === '{}' || currentUserData === null || currentUserData === undefined){
            setIsUpdating(false);
            setUpdateProgressStatus('fail');
            return;
        }else{
            setIsUpdating(true);
            // Extract the list to work on.
            const gameList = currentUserData['game_collection']

            // Retrieve the current object.
            const currentGameObject = gameList.find((currGameObj) => currGameObj.game_id === gameObject.game_id);
            
            // Now delete the object from the current list.
            const newGameList = gameList.filter((game) => game.game_id !== gameObject.game_id);

            // Make changes in the obtained old game object.
            const newGameObject = {
                ...currentGameObject,
                'status':'playing',
                'progress': newProgress
            }
            // Add this new object to the newGameList
            var finalNewGameList = [...newGameList, newGameObject];
            var sortedRankWiseList = finalNewGameList.sort((gameA, gameB) => gameB.progress - gameA.progress);
            // console.log('newGameList => ',finalNewGameList);

            // Finally update the firestore document with the new list.
            const userDocRef = doc(db, `Users/${currentUserID.uid}/PrivateInfo`, 'userInfo');
            updateDoc(userDocRef, {
                 game_collection: sortedRankWiseList
            }).then(()=>{
                setIsUpdating(false);
                setUpdateProgressStatus('success');
                setUpdatedGameList(sortedRankWiseList);
                return;
            }).catch((err)=>{
                setIsUpdating(false);
                setUpdateProgressStatus('fail')
                return;   
            })
        }
    }

    // TO UPDATE THE STATUS OF THE GAME.
    const updateGameStatus = async(gameObject, newStatus)=>{
        if(JSON.stringify(currentUserData) === '{}' || currentUserData === null || currentUserData === undefined){
            setIsUpdating(false);
            setUpdatePlayingStatus('fail');
            return;
        }else{
            setIsUpdating(true);
            // Extract the list to work on.
            const gameList = currentUserData['game_collection']

            // Retrieve the current object.
            const currentGameObject = gameList.find((currGameObj) => currGameObj.game_id === gameObject.game_id);
            
            // Now delete the object from the current list.
            const newGameList = gameList.filter((game) => game.game_id !== gameObject.game_id);

            // Make changes in the obtained old game object.
            var newGameObject = {}
            if(newStatus === 'paused'){
                newGameObject = {
                    ...currentGameObject,
                    'status':'paused'
                }
            }else if(newStatus === 'beat'){
                newGameObject = {
                    ...currentGameObject,
                    'status':'beat',
                    'progress': 100
                }
            }else if(newStatus === 'quit'){
                newGameObject = {
                    ...currentGameObject,
                    'status':'quit'
                }
            }
            
            // Add this new object to the newGameList
            var finalNewGameList = [...newGameList, newGameObject];
            var sortedRankWiseList = finalNewGameList.sort((gameA, gameB) => gameB.progress - gameA.progress);
            // console.log('newGameList => ',finalNewGameList);

            // Finally update the firestore document with the new list.
            const userDocRef = doc(db, `Users/${currentUserID.uid}/PrivateInfo`, 'userInfo');
            updateDoc(userDocRef, {
                 game_collection: sortedRankWiseList
            }).then(()=>{
                setIsUpdating(false);
                setUpdatePlayingStatus('success');
                setUpdatedGameList(sortedRankWiseList);
                return;
            }).catch((err)=>{
                setIsUpdating(false);
                setUpdatePlayingStatus('fail')
                return;   
            })
        }
    }

    // TO UPDATE THE RANK OF THE GAME IN THE USER COLLECTION
    // TO UPDATE THE RANK OF THE GAME IN THE USER COLLECTION

    return { isUpdating, updateProgressStatus, updatedGameList, updateUserGameProgress,
        updateGameStatus, updatePlayingStatus
     };
}