// styles
import './homepage.css';

// Ad component
import AdComponent from '../../Ads/AdComponent';
import BannerAdComponent from '../../Ads/BannerAdComponent';
import { banner_ad_source_1_key } from '../../Ads/ad_sources';

// Components
import HottestGameBanner from '../../Components/HomepageComponents/TrendingGameBanner/trendingGameBanner';
import TrendingReviewArea from '../../Components/HomepageComponents/TrendingReview/trendingReviewArea';
import TrendingForumArea from '../../Components/HomepageComponents/TrendingForums/trendingForumArea';
import EditorsPick from '../../Components/HomepageComponents/EditorsPick/editors_pick';

// assets
import mainLogoImg from '../../assets/Logo/LOGO-2.png';

// SEO
import { Helmet } from 'react-helmet';

export default function Homepage(){

    return(
        <>
        <div className='homepage-container'>
            <Helmet>
                {/* {seoInfo && <title>{seoInfo.name}</title>} */}
                <meta name='tagline' content={'For the gamers. By the gamers.'}/>
                {/* { seoInfo && <meta name='description' content={`${seoInfo.name}, ${seoInfo.publisher_name}`}/> } */}
                <meta name='description' content={"games, free games, online games, new games, pc games, play games, best games, gaming, multiplayer games, action games, shooting games, racing games, adventure games, console games, mobile games, esports, gaming news, game reviews"}/>
                <meta name='og:image' content={mainLogoImg}/>
            </Helmet>
            <div className="homepage-ad-components-1">
            </div>
            <div className="homepage-contents">
                <HottestGameBanner/>
                <div className="vertical-space-div"></div>
                <TrendingForumArea/>
                <div className="vertical-space-div"></div>
                <TrendingReviewArea/>
                <div className="vertical-space-div"></div>
                {/* <div className="banner-ad-container">
                    <BannerAdComponent banner_key={banner_ad_source_1_key}/>
                </div> */}
                <div className="vertical-space-div"></div>
                <EditorsPick/>
                <div className="vertical-space-div"></div>
            </div>
            {/* <div className="homepage-ad-components-2">
                <AdComponent container_id={"container-fa2a51f9784b9ba7cd360219cfdd7802"}/>
            </div> */}
            {/* 
            <RandomReview/>
            <EditorsPick/>
            <RecentReleases/> */}
        </div>
        </>
    )
}