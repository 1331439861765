/* eslint-disable react-hooks/exhaustive-deps */
// styles
import './mainGamePage.css';
// react states
import { useEffect, useState } from 'react';
// react redux
import { useSelector } from 'react-redux';
import { selectUser } from '../../Features/userSlice';
import { selectUserData } from '../../Features/userPrivateDataSlice';
// react router dom
import { useNavigate, useParams } from 'react-router-dom';
// custom hooks
import { convertDateUtility } from '../../Hooks/DateUtility/convertDateUtility';
import { useGetGenre } from '../../Hooks/Genres/useGetGenre';
import { useFetchGameDetails } from '../../Hooks/GameData/useFetchGameDetails';
import { useAddToProfile } from '../../Hooks/GameUserInteraction/useAddToProfile';
import { useRemoveFromProfile } from '../../Hooks/GameUserInteraction/useRemoveFromProfile';
// custom components
import MainGameReviews from '../../Components/MainGamePageComponents/MainGameReview/mainGameReview';
import MainGameGallerySection from '../../Components/MainGamePageComponents/MainGameGallery/mainGameGallery';
// mui icons
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { CircularProgress } from '@mui/material';
// For SEO purposes
import { Helmet } from 'react-helmet';

export default function MainGamePage(){

    // To get the user data
    const signedInUser = useSelector(selectUser);
    const signedInUserData = useSelector(selectUserData);

    // To get the game_id to fetch the game details
    const { gameid } = useParams();
    const { data: game, isLoading } = useFetchGameDetails(gameid);

    // To check whether the game is present in the user's game library or not.
    const [isInLibrary, setIsInLibrary] = useState(false);
    useEffect(()=>{
        if(signedInUserData !== null && signedInUserData !== undefined){
            const libraryList = signedInUserData.game_collection;
            const isPresent = libraryList.some((gameObj) => gameObj.game_id === gameid)
            setIsInLibrary(isPresent);
        }
    },[signedInUserData])

    console.log(signedInUserData);
    console.log('isInLibrary => ', isInLibrary);

    // To get the genres from the game details loaded
    const { getMultipleGenreData, multipleGenreData } = useGetGenre();

    // console.log(isLoading, game);
    // Just to get date in required format.
    const [releaseDate, setReleaseDate] = useState(null);

    useEffect(()=>{
        if(game !== null && game !== undefined){
            setReleaseDate(convertDateUtility(game.year_of_release));
            getMultipleGenreData(game.genres);
        }
    },[game])

    // For navigation to other pages if need arises.
    const navFromGamePage = useNavigate(); 

    // To navigate to all the user reviews
    const navigateToUserReviews = ()=>{
        navFromGamePage(`/game_reviews/${gameid}`);
    }

    // To navigate to the clicked platform
    const navigateToPlatform = (console_id)=>{
        navFromGamePage(`/consoles/${console_id}`)
    }

    // To navigate to the clicked genre
    const navigateToGenre = (genre_id)=>{
        navFromGamePage(`/genres/${genre_id}`)
    }

    // To Add game to user profile
    const { addGameToUserCollection, addGameToUserWishlist, addingToWishlist, addingToProfile } = useAddToProfile();
    const addToProfile = ()=>{
        if(signedInUser === null || signedInUser === undefined){
            navFromGamePage('/user_auth');
            return;
        }
        if(game !== undefined && game !== null){
            addGameToUserCollection(game);
        }
    }
    const removeFromProfile = ()=>{
        if(signedInUser === null || signedInUser === undefined){
            navFromGamePage('/user_auth');
            return;
        }
        if(game !== undefined && game !== null){
            removeFromUserCollection(game);
        }
    }
    // To remove game from user profile
    const { removeFromUserCollection, removeFromUserWishlist, removing, removingFromWishlist } = useRemoveFromProfile();
    const addToWishlist = ()=>{
        if(signedInUser === null || signedInUser === undefined){
            navFromGamePage('/user_auth');
            return;
        }
        if(game !== undefined && game !== null){
            addGameToUserWishlist(game);
        }
    }
    const removeFromWishlist = ()=>{
        if(signedInUser === null || signedInUser === undefined){
            navFromGamePage('/user_auth');
            return;
        }
        if(game !== undefined && game !== null){
            removeFromUserWishlist(game);
        }
    }

    if(isLoading)
        return <div>...Loading</div>

    if(!game)
        return <div>...Loading game...</div>

    return(
        <div className="main-game-page-container">
            <Helmet>
            <title>{game.name}</title>
            <meta name="description" content={game.synopsis} />
            <meta property="og:title" content={game.name} />
            <meta property="og:description" content={game.synopsis} />
            <meta property="og:url" content={`https://thegamerbroz.com/game_page/${gameid}`} />
            <link rel="canonical" href={`https://thegamerbroz.com/game_page/${gameid}`}/>
            {
                game.total_reviews > 0 &&
                <script type="application/ld+json">
                {
                    `{
                        "@context": "https://schema.org",
                        "@type": "VideoGame",
                        "name": "${game.name}",
                        "aggregateRating": {
                            "@type": "AggregateRating",
                            "ratingValue": "${(game.score)/(game.total_reviews)}",
                            "reviewCount": "${game.total_reviews}",
                            "bestRating": "10",
                            "worstRating": "1"
                        },
                        "author": {
                            "@type": "Organization",
                            "name": "The Gamer Bros"
                        },
                        "publisher": {
                            "@type": "Organization",
                            "name": "${game.publisher_name}"
                        },
                        "applicationCategory": "Game",
                        "datePublished": "${game.year_of_release}"
                    }`
                    
                }   
                </script>
            }
            </Helmet>
            <header className='main-game-header'>
            {
                game !== undefined && game !== null &&
                <img src={game.cover_image_url} alt={game.name} />
            }
            <div className="main-game-banner-overlay">
                <div className="main-game-about-details">
                    <h1 className='main-game-page-heading'>{game.name}</h1>
                    <div className="main-game-info-area">
                        {
                            releaseDate !== null && releaseDate !== undefined &&
                            <div className="main-game-info-box">
                                <h4 className='main-game-page-heading'>Release date</h4>
                                <p className='main-game-text'>{releaseDate}</p>
                            </div>
                        }
                        <div className="main-game-info-box">
                            <h4 className='main-game-page-heading'>Developers</h4>
                            <p className='main-game-text'>{game.studio_name}</p>
                        </div>
                        <div className="main-game-info-box">
                            <h4 className='main-game-page-heading'>Publishers</h4>
                            <p className='main-game-text'>{game.publisher_name}</p>
                        </div>
                    </div>
                    <div className="main-game-info-box">
                        <h4 className='main-game-page-heading'>Available on</h4>
                        <div className="main-game-platform-area">
                        {
                            game.platforms.map((platform, index)=>(
                                <button key={platform} className='main-game-platform-btn'
                                        onClick={()=>{
                                            console.log(platform)
                                            navigateToPlatform(platform.id)
                                        }}
                                >
                                {
                                    index !== game.platforms.length - 1 &&
                                    `${platform}, `
                                }{
                                    index === game.platforms.length - 1 &&
                                    `${platform}`
                                }
                                </button>
                            ))
                        }
                        </div>
                    </div>
                    <div className="main-game-info-box">
                        <h4 className='main-game-page-heading'>Genres</h4>
                        <div className="main-game-platform-area">
                        {
                            multipleGenreData && multipleGenreData.length > 0 &&
                            multipleGenreData.map((current_genre, index)=>(
                                <button key={current_genre.id} className='main-game-platform-btn'
                                        onClick={()=>navigateToGenre(current_genre.id)}
                                >
                                {
                                    index !== multipleGenreData.length - 1 &&
                                    `${current_genre.name}, `
                                }
                                {
                                    index === multipleGenreData.length - 1 &&
                                    `${current_genre.name}`
                                }
                                </button>
                            ))
                        }
                        </div>
                    </div>
                    <div className="main-game-profile-action-box">
                        {
                            (signedInUser === null || signedInUser === undefined)
                            &&
                            <button className="main-game-action-btn"
                                 onClick={()=>addToProfile()}
                            >
                                Add to collection
                            </button>
                        }
                        {
                            JSON.stringify(signedInUserData)!=='{}'
                            && isInLibrary === false
                            // && (!signedInUserData.game_collection.includes(game.id))
                            && <button className="main-game-action-btn"
                                onClick={()=>addToProfile()}
                            >
                                {
                                    addingToProfile === true &&
                                    <CircularProgress size={18} style={{ color:'#080808' }}/>
                                }
                                {
                                    addingToProfile === false &&
                                    'Add to Collection'
                                }
                            </button>
                        }
                        {
                            JSON.stringify(signedInUserData)!=='{}'
                            && isInLibrary === true
                            // && (signedInUserData.game_collection.includes(game.id))
                            && <button className="main-game-action-btn"
                                    onClick={()=>removeFromProfile()}
                            >
                                {
                                    removing === true &&
                                    <CircularProgress size={18} style={{ color:'#080808' }}/>
                                }
                                {
                                    removing === false &&
                                    'Remove from collection'
                                }
                            </button>
                        }
                        {
                            (signedInUser === null || signedInUser === undefined)
                            &&
                            <button className="main-game-action-btn"
                                 onClick={()=>addToWishlist()}
                            >
                                Add to Wishlist
                            </button>
                        }
                        {
                            JSON.stringify(signedInUserData)!=='{}'
                            && (!signedInUserData.wishlist.includes(game.id))
                            && <button className="main-game-action-btn"
                                    onClick={()=>addToWishlist()}
                            >
                                {
                                    addingToWishlist === true &&
                                    <CircularProgress size={18} style={{ color:'#080808' }}/>
                                }
                                {
                                    addingToWishlist === false &&
                                    'Add to Wishlist'
                                }
                            </button>
                        }
                        {
                            JSON.stringify(signedInUserData)!=='{}'
                            && (signedInUserData.wishlist.includes(game.id))
                            && <button className="main-game-action-btn"
                                    onClick={()=>removeFromWishlist()}
                            >
                                {
                                    removingFromWishlist === true &&
                                    <CircularProgress size={18} style={{ color:'#080808' }}/>
                                }
                                {
                                    removingFromWishlist === false &&
                                    'Remove from Wishlist'
                                }
                            </button>
                        }
                    </div>
                </div>
            </div>
            </header>
            <main className='main-game-main-area'>
                <section className='main-game-ad-section-1'>
                        {/* Hello this is ad1 */}
                </section>
                <section className='main-game-info-section'>
                    <div className="main-game-additional-info-area">
                        <div className="main-game-thumb-img-container">
                            <img src={game.thumbnail_image} alt={game.name} />
                        </div>
                        <div className="main-game-add-info-txt-area">
                            <div className="main-game-info-box">
                                {
                                    game.total_reviews === 0 &&
                                    <h1 className='main-game-page-heading-2'>About</h1>
                                }
                                {
                                    game.total_reviews > 0 && 
                                    <>
                                        <h3 className='main-game-page-heading'>Aggregate Score</h3>
                                        <h1 className='main-game-page-heading-2'>{(game.score)/(game.total_reviews)}/10</h1>
                                    </>
                                }
                            </div>
                            <div className="main-game-synopsis-area">
                                <p className='main-game-text'>{game.synopsis}</p>
                            </div>
                            <div className="main-game-profile-action-box">
                                <button className='main-game-action-btn'
                                        onClick={()=>{
                                            navFromGamePage(`/write_game_review/${gameid}`)
                                        }}
                                >
                                    Add a review
                                </button>
                            </div>
                        </div>
                    </div>
                    <section className='main-game-rev-section'>
                        <div className="main-game-rev-section-heading">
                            <h1 className='main-game-page-heading'>Reviews</h1>
                            {
                                game.total_reviews > 2 &&
                                <button className='main-game-text-btn'
                                        onClick={()=>navigateToUserReviews()}
                                >
                                    Read all reviews
                                    <ArrowForwardIcon className='review-btn-arrow-icon'/>
                                </button>
                            }
                        </div>
                        <MainGameReviews gameID={gameid}/>
                    </section>
                    <section className='main-game-gallery-section'>
                        <h1 className='main-game-page-heading'>Gallery</h1>
                        <MainGameGallerySection gameID={gameid}/>    
                    </section>
                </section>
                <section className="main-game-additional-info-section">
                {
                    game.minRequirement !== null && game.minRequirement !== undefined &&
                    <div className="main-game-min-requirement">
                        <h4 className='main-game-page-heading'>Minimum Requirement</h4>
                        <p className='main-game-text'>{`OS: ${game.minRequirement.OS}`}</p>
                        <p className='main-game-text'>{`Version: ${game.minRequirement.OS_Version}`}</p>
                        <p className='main-game-text'>{`RAM: ${game.minRequirement.RAM}`}</p>
                        <p className='main-game-text'>{`Graphics Card: ${game.minRequirement.Graphics_card}`}</p>
                        <p className='main-game-text'>{`Storage: ${game.minRequirement.Storage}`}</p>
                    </div>
                }
                {
                    game.recommendedRequirement !== null && game.recommendedRequirement !== undefined &&
                    <div className="main-game-min-requirement">
                        <h4 className='main-game-page-heading'>Recommended Requirement</h4>
                        <p className='main-game-text'>{`OS: ${game.recommendedRequirement.OS}`}</p>
                        <p className='main-game-text'>{`Version: ${game.recommendedRequirement.OS_Version}`}</p>
                        <p className='main-game-text'>{`RAM: ${game.recommendedRequirement.RAM}`}</p>
                        <p className='main-game-text'>{`Graphics Card: ${game.recommendedRequirement.Graphics_card}`}</p>
                        <p className='main-game-text'>{`Storage: ${game.recommendedRequirement.Storage}`}</p>
                    </div>
                }
                </section>
            </main>
        </div>
    )
}