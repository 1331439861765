// react states
import { useState, useEffect } from 'react';
// firebase
import { db } from '../Firebase/firebase';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';

export const useRecentReleases = ()=>{
    const [error, setError] = useState(null);
    const [loadingReleases, setLoadingReleases] = useState(false);
    const [releases, setReleases] = useState(null);
    const [hottestGame, setHottestGame] = useState(null);

    useEffect(()=>{
        const getRecentReleases = async()=>{
            setLoadingReleases(true);
            const releasesQuerySnapshot = await getDocs(collection(db, "RecentReleases"))
                                            .catch((err)=>{
                                                setError(err.message);
                                                setLoadingReleases(false);
                                                return;
                                            });
            console.log(error);
            let tempReleaseArray = [];
            for(const release of releasesQuerySnapshot.docs){

                const docRef = doc(db, "Games",`${release.data().game_id}` );
                const docSnap = await getDoc(docRef)
                .catch((err)=>{
                    setError(err.message);
                    setLoadingReleases(false);
                    return;
                });

                if (docSnap.exists()) {
                    if(release.data().isHottest === true){
                        setHottestGame({
                            'game_id':release.data().game_id,
                            ...docSnap.data(),
                            ...release.data()
                        })

                        const tempGameObj = {
                            'game_id':release.data().game_id,
                            ...docSnap.data()
                        }

                        tempReleaseArray.push(tempGameObj);

                    }

                    if(release.data().isHottest === false){
                        const tempNormalGameObj = {
                            'game_id':release.data().game_id,
                            ...docSnap.data()
                        }

                        tempReleaseArray.push(tempNormalGameObj);
                    }
                }
            }

            if(tempReleaseArray.length > 0){
                setReleases(tempReleaseArray);
            }

            setLoadingReleases(false);
        }
        getRecentReleases();
    },[]);

    return { error, loadingReleases, releases, hottestGame };
}