import { createSlice } from '@reduxjs/toolkit';

// firebase
// import { db } from '../Firebase/firebase';
// import { doc, updateDoc, arrayUnion } from 'firebase/firestore';

const addGamesToUserCollection = async( current_state, data ) => {
    if(JSON.stringify(current_state.user_data) !== '{}'){
        // current_state.user_data.game_collection.push(data.gameID);
        current_state.user_data.game_collection.push(data.gameObj);
        setPrivateData(current_state.user_data);
    }
}

const addGamesToUserWishlist = async( current_state, data ) => {
    if(JSON.stringify(current_state.user_data) !== '{}'){
        current_state.user_data.wishlist.push(data.gameID);
        setPrivateData(current_state.user_data);
    }
}

const addGamesToCurrentlyPlaying = async( current_state, data ) => {
    if(JSON.stringify(current_state.user_data) !== '{}'){
        current_state.user_data.currently_playing.push(data.gameID);
        setPrivateData(current_state.user_data);
    }
    
}

const removeGameFromCurrentlyPlaying = async( current_state, data ) => {
    if(JSON.stringify(current_state.user_data) !== '{}'){
        var tempArr = current_state.user_data.currently_playing.filter((id)=> id !== data.gameID);
        current_state.user_data.currently_playing = tempArr;
        setPrivateData(current_state.user_data);
    }
}

const removeGamesFromUserCollection = async( current_state, data ) => {
    // current_state.user_data.game_collection.push(data.gameID);
    if(JSON.stringify(current_state.user_data) !== '{}'){
        var tempArr = current_state.user_data.game_collection.filter((game_obj)=> game_obj.game_id !== data.gameID);
        current_state.user_data.game_collection = tempArr;
        setPrivateData(current_state.user_data);
    }
}

const removeGamesFromUserWishlist = async( current_state, data ) => {
    if(JSON.stringify(current_state.user_data) !== '{}'){
        var tempArr = current_state.user_data.wishlist.filter((id)=> id !== data.gameID);
        current_state.user_data.wishlist = tempArr;
        setPrivateData(current_state.user_data);
    }
}

const addGamesToUserReviewed = async(current_state, data) => {
    if(JSON.stringify(current_state.user_data) !== '{}'){
        current_state.user_data.games_reviewed.push(data.gameID);
        setPrivateData(current_state.user_data);
    }
}

const removeGameFromUserReviewed = async(current_state, data)=>{
    if(JSON.stringify(current_state.user_data) !== '{}'){
        var tempArr = current_state.user_data.games_reviewed.filter((id)=> id !== data.gameID);
        current_state.user_data.games_reviewed = tempArr;
        setPrivateData(current_state.user_data);
    }
}

// Console addition and removal
const addConsoleToUserCollection = async( current_state, data ) => {
    if(JSON.stringify(current_state.user_data) !== '{}'){
        current_state.user_data.all_consoles.push(data.consoleID);
        setPrivateData(current_state.user_data);
    }
}
const removeConsoleFromUserCollection = async(current_state, data)=>{
    if(JSON.stringify(current_state.user_data) !== '{}'){
        var tempArr = current_state.user_data.all_consoles.filter((id)=> id !== data.consoleID);
        current_state.user_data.all_consoles = tempArr;
        setPrivateData(current_state.user_data);
    }
}

const userDataSlice = createSlice({
    'name':'userPrivateData',
    initialState : {
        user_data:{}
    },

    reducers:{
        setPrivateData:(state, action)=>{
            state.user_data = action.payload;
        },
        unsetPrivateData:(state)=>{
            state.user_data = {};
            // state.user_data = null;
        },
        addConsoleToUser:(state, action)=>{
            addConsoleToUserCollection(state, action.payload);
        },
        removeConsoleFromUser:(state, action)=>{
            removeConsoleFromUserCollection(state, action.payload);
        },
        addGamesToUser:(state, action)=>{
            addGamesToUserCollection(state, action.payload);
        },
        addGameToWishlist:(state, action)=>{
            addGamesToUserWishlist(state, action.payload);
        },
        removeGamesFromUser:(state, action)=>{
            removeGamesFromUserCollection(state, action.payload);
        },
        removeGameFromWishlist:(state, action)=>{
            removeGamesFromUserWishlist(state, action.payload);
        },
        addToCurrentlyPlaying:(state, action)=>{
            addGamesToCurrentlyPlaying(state, action.payload);
        },
        removeFromCurrentlyPlaying:(state, action)=>{
            removeGameFromCurrentlyPlaying(state, action.payload);
        },
        addGameToReviewed:(state, action)=>{
            addGamesToUserReviewed(state, action.payload);
        },
        removeGameFromReviewed:(state, action)=>{
            removeGameFromUserReviewed(state, action.payload);
        }
    },

})

export const { setPrivateData, unsetPrivateData, addGamesToUser, addGameToWishlist,
               removeGamesFromUser, removeGameFromWishlist, addToCurrentlyPlaying,
               removeFromCurrentlyPlaying, addGameToReviewed, removeGameFromReviewed,
               addConsoleToUser, removeConsoleFromUser
} = userDataSlice.actions;
export const selectUserData = (state) => state.userPrivateData.user_data;

export default userDataSlice.reducer;