// styles
import './privacy_policy.css';

export default function PrivacyPolicyPage(){

    return(
        <div className="privacy-policy-container">
            <div className="privacy-policy-area">
                <p className="pp-heading">Privacy Policy</p>
                <p className="pp-bold-text">Last Updated: 2nd April 2025</p>
                <p className="pp-normal-text">
                    <span className='pp-bold-text'>The Gamer Bros</span> ("we", "our", "us") is committed to protecting and respecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website https://thegamerbroz.com, including any other media form, media channel, mobile website, or mobile application related or connected thereto (collectively, the "Site"). Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the site.
                </p>
                <div className="pp-space-div"></div>
                <p className='pp-subheading'>1. Information we collect</p>
                <p className='pp-normal-text'>
                    We may collect information about you in a variety of ways. The information we may collect on the Site includes:
                </p>
                <div className="pp-space-div"></div>
                <p className='pp-bold-text'>Personal Data</p>
                <p className='pp-normal-text'>
                    Personally identifiable information, such as your name, email address, and telephone number, and demographic information, such as your age, gender, locations and interests, that you voluntarily give to us when you register with the Site or when you choose to participate in various activities related to the Site, such as online forums and reviews. If you choose to share data about yourself via your profile, online forums, or other interactive areas of the Site, please be advised that all data you disclose in these areas is public and your data will be accessible to anyone who accesses the Site.
                </p>
                <div className="pp-space-div"></div>
                <p className='pp-bold-text'>Derivative Data</p>
                <p className='pp-normal-text'>
                    Information our servers automatically collect when you access the Site, such as your IP address, your browser type, your operating system, your access times. If you are using our mobile application, this information may also include your device name and type, your operating system, your phone number, your country, and other interactions with the application and other users via server log files, as well as any other information you choose to provide.
                </p>
                <div className="pp-space-div"></div>
                <p className='pp-bold-text'>Financial Data</p>
                <p className='pp-normal-text'>
                    Financial information, such as data related to your payment method (e.g., valid credit card number, card brand, expiration date) that we may collect when you purchase, order, return, exchange, or request information about our services from the Site. We store only very limited, if any, financial information that we collect. Otherwise, all financial information is stored by our payment processor, 
                    <span className='pp-bold-text'>
                        <a href='https://stripe.com/gb/privacy'
                           target='_blank'
                           rel='noreferrer'
                        >
                            Stripe
                        </a>
                    </span>
                    , and you are encouraged to review their privacy policy and contact them directly for responses to your questions.
                </p>
                <div className="pp-space-div"></div>
                <p className='pp-bold-text'>Data From Social Networks</p>
                <p className='pp-normal-text'>
                    User information from social networking sites, such as Instagram, Youtube, including your name, your social network username, location, gender, birth date, email address, profile picture, and public data for contacts, if you connect your account to such social networks.    
                </p>
                <div className="pp-space-div"></div>
                <div className="pp-space-div"></div>
                <p className='pp-subheading'>2. Use of Your Information</p>
                <p className='pp-normal-text'>
                    Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the Site to:
                </p>
                <div className="pp-space-div"></div>
                <p className='pp-normal-text'>
                    - Create and manage your account.
                </p>
                <p className='pp-normal-text'>
                    - Process your transactions and send you related information, including purchase confirmations and invoices.
                </p>
                <p className='pp-normal-text'>
                    - Administer sweepstakes, promotions, and contests.
                </p>
                <p className='pp-normal-text'>
                    - Email you regarding your account or order.
                </p>
                <p className='pp-normal-text'>
                    - Fulfill and manage purchases, orders, payments, and other transactions related to the Site.
                </p>
                <p className='pp-normal-text'>
                    - Improve the Site to better serve you.
                </p>
                <p className='pp-normal-text'>
                    - Monitor and analyze usage and trends to improve your experience with the Site.
                </p>
                <p className='pp-normal-text'>
                    - Notify you of updates to the Site.
                </p>
                <p className='pp-normal-text'>
                    - Offer new products, services, mobile applications, and/or recommendations to you.
                </p>
                <p className='pp-normal-text'>
                    - Perform other business activities as needed.
                </p>
                <p className='pp-normal-text'>
                    - Request feedback and contact you about your use of the Site.
                </p>
                <p className='pp-normal-text'>
                    - Resolve disputes and troubleshoot problems.
                </p>
                <p className='pp-normal-text'>
                    - Respond to product and customer service requests.
                </p>
                <div className="pp-space-div"></div>
                <div className="pp-space-div"></div>
                <p className='pp-subheading'>3. Disclosure of Your Information</p>
                <p className='pp-normal-text'>
                    We may share information we have collected about you in certain situations. Your information may be disclosed as follows:
                </p>
                <div className="pp-space-div"></div>
                <p className='pp-bold-text'>By Law or to Protect Rights</p>
                <p className='pp-normal-text'>
                    If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation. This includes exchanging information with other entities for fraud protection and credit risk reduction.
                </p>
                <div className="pp-space-div"></div>
                <p className='pp-bold-text'>
                    Business Transfers
                </p>
                <p className='pp-normal-text'>
                    We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
                </p>
                <p className='pp-space-div'></p>
                <p className='pp-bold-text'>
                    Third-Party Service Providers
                </p>
                <p className='pp-normal-text'>
                    We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.
                </p>
                <div className="pp-space-div"></div>
                <p className='pp-bold-text'>
                    Marketing Communications
                </p>
                <p className='pp-normal-text'>
                    With your consent, or with an opportunity for you to withdraw consent, we may share your information with third parties for marketing purposes, as permitted by law.
                </p>
                <div className="pp-space-div"></div>
                <p className='pp-bold-text'>
                    Interactions with Other Users
                </p>
                <p className='pp-normal-text'>
                    If you interact with other users of the Site, those users may see your name, profile photo, and descriptions of your activity, including interaction with other users, liking forums, reviews, forum comments and review comments.
                </p>
                <div className="pp-space-div"></div>
                <p className='pp-bold-text'>
                    Online Postings
                </p>
                <p className='pp-normal-text'>
                    When you post comments, contributions, or other content to the Site, your posts may be viewed by all users and may be publicly distributed outside the Site in perpetuity.
                </p>
                <div className="pp-space-div"></div>
                <div className="pp-space-div"></div>
                <p className='pp-subheading'>4. Affiliate Disclosure</p>
                <p className='pp-normal-text'>
                    Our Site contains affiliate links to products and services. If you click these links and make a purchase, we may earn a commission at no extra cost to you. We only recommend products we believe offer value to our users. We currently participate in the following affiliate programs:
                </p>
                <div className="pp-space-div"></div>
                <p className='pp-normal-text'>
                    - Amazon Affiliate Program.
                </p>
                <div className="pp-space-div"></div>
                <p className='pp-normal-text'>Affiliate tracking may involve the use of cookies or other tracking technologies by the affiliate network or merchant.</p>
                <div className="pp-space-div"></div>
                <div className="pp-space-div"></div>
                <p className='pp-subheading'>5. Tracking Technologies</p>
                <p className='pp-normal-text'>
                    We may use cookies, web beacons, tracking pixels, and other tracking technologies on the Site to help customize the Site and improve your experience. When you access the Site, your personal information is not collected through the use of tracking technology. Most browsers are set to accept cookies by default. You can remove or reject cookies, but be aware that such action could affect the availability and functionality of the Site.
                </p>
                <div className="pp-space-div"></div>
                <div className="pp-space-div"></div>
                <p className='pp-subheading'>6. Security of Your Information</p>
                <p className='pp-normal-text'>
                    We use administrative and technical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.
                </p>
                <div className="pp-space-div"></div>
                <div className="pp-space-div"></div>
                <p className='pp-subheading'>7. Policy for Children</p>
                <p className='pp-normal-text'>
                    We do not knowingly solicit information from or market to children under the age of 13. If we learn that we have collected personal information from a child under age 13 without verification of parental consent, we will delete that information as quickly as possible. If you believe we might have any information from or about a child under 13, please contact us at -  
                    <span className='pp-bold-text'>
                        thegamerbros.tgb.2022@gmail.com
                    </span> 
                    .
                </p>
                <div className="pp-space-div"></div>
                <div className="pp-space-div"></div>
                <p className='pp-subheading'>8. Changes to This Privacy Policy</p>
                <p className='pp-normal-text'>
                    We may update this Privacy Policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons. If we make material changes to this policy, we may notify you by email or by means of a notice on our site.
                </p>
                <div className="pp-space-div"></div>
                <div className="pp-space-div"></div>
                <p className='pp-subheading'>9. Contact us</p>
                <p className='pp-normal-text'>
                    If you have questions or comments about this Privacy Policy, please contact us at:
                </p>
                <p className='pp-bold-text'>
                    thegamerbros.tgb.2022@gmail.com
                </p>
                <div className="pp-space-div"></div>
                <div className="pp-space-div"></div>
                <div className="pp-ending-line">
                    <p className='pp-bold-text'>---- X ----</p>
                </div>
                <div className="pp-space-div"></div>
                <div className="pp-space-div"></div>
            </div>
        </div>
    )
}
