// styles
import './marketplaceHomepage.css';
import { useEffect, useState } from 'react';
import { useMarketplaceSearch } from '../../Hooks/Marketplace/useMarketplaceSearch';
import ProductTile from '../../Components/MarketplaceComponents/productTile';
// mui components
import { Button, Dialog, DialogContent, TextField,
    FormGroup, FormControlLabel, Checkbox,
    DialogActions, Skeleton,
    Card
 } from '@mui/material';
import { CheckBoxOutlineBlankOutlined } from '@mui/icons-material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import FilterListIcon from '@mui/icons-material/FilterList';
import styled from '@emotion/styled';

const CustomCheckbox = styled((props) => (
    <Checkbox
      {...props}
      icon={<CheckBoxOutlineBlankOutlined />}
      checkedIcon={<CheckBoxIcon />}
    />
    ))(() => ({
        color: '#ffffff', // border for unchecked
        '&.Mui-checked': {
            color: '#ffffff', // white background
        },
        '& .MuiSvgIcon-root': {
            fontSize: 28, // adjust as needed
        },
        '&.Mui-checked .MuiSvgIcon-root': {
            backgroundColor: '#ffffff',
            borderRadius: 4,
            color: '#191917', // black tick
        },
}));
  
const StyledFormControlLabel = styled(FormControlLabel)(() => ({
    color: '#ffffff', // Label text color
    marginBottom: 8,
    alignItems: 'center',
}));

export default function MarketplaceHomepage(){

    const [searchText, setSearchText] = useState("");
    const handleTextChange = (searchEvent)=>{
        setSearchText(searchEvent.target.value);
    }

    // CUSTOM HOOK FOR PRODUCT SEARCHING
    const { searching, productList, 
        // searchError, 
        productTextSearch
    } = useMarketplaceSearch();
    // console.log(productList);
    // console.log('searching => ', searching);

    // For Filters
    // For country filtering
    const [countryFilter, setCountryFilter] = useState({
        'India': true,
        'UK': true
    });

    const [openFilterBox, setOpenFilterBox] = useState(false);
    const handleOpenFilterBox = ()=>{
        setOpenFilterBox(true);
    }
    const handleCloseFilterBox = ()=>{
        setOpenFilterBox(false);
    }

    // For toggling the country filters
    const toggleCountryFilter = (country) => {
        setCountryFilter((prev)=>({
            ...prev,
            [country] : !prev[country]
        }))
    }
    // For Filters
    const handleProductSearch = (text, filter)=>{
        productTextSearch(text, filter);
    }
    useEffect(()=>{
        handleProductSearch(searchText, countryFilter);
        // eslint-disable-next-line
    },[searchText, countryFilter]);

    return(
        <div className="marketplace-homepage">
            
            {/* DIALOG BOX FOR FILTERS */}
            <Dialog open={openFilterBox}
                    onClose={handleCloseFilterBox}
                    fullWidth={true}
                    maxWidth={'sm'}
            >
                <DialogContent dividers={false} sx={{ backgroundColor:'#191917', display:'flex', flexDirection:'column', justifyContent:'flex-start',
                    alignItems:'flex-start', boxSizing:'border-box', padding:'1.5%'
                 }}>
                    <p className='marketplace-filter-heading'>Country</p>
                    <FormGroup sx={{ boxSizing:'border-box', margin:'10px' }}>
                        <StyledFormControlLabel
                            control={
                                <CustomCheckbox
                                checked={countryFilter.India}
                                onChange={() => toggleCountryFilter('India')}
                                />
                            }
                            label="India"
                        />
                        <StyledFormControlLabel
                            control={
                                <CustomCheckbox
                                checked={countryFilter.UK}
                                onChange={() => toggleCountryFilter('UK')}
                                />
                            }
                            label="United Kingdom"  
                        />
                    </FormGroup>
                </DialogContent>
                <DialogActions sx={{ backgroundColor:'#191917' }}>
                    <Button variant='contained' sx={{ backgroundColor:'#ffe74c', color:'#080808', fontFamily:'Paciencia', fontWeight:'600',
                            "&:hover": {
                                backgroundColor: "#FFD700"
                            }
                        }}
                        onClick={()=>{
                            handleProductSearch(searchText, countryFilter)
                            handleCloseFilterBox();
                        }}
                    >
                        Apply Filter
                    </Button>
                </DialogActions>
            </Dialog>
            {/* DIALOG BOX FOR FILTERS */}

            <div className="marketplace-heading-area">
                <p className="marketplace-heading">The Marketplace</p>
                <p className="marketplace-subheading">{"(This site contains affiliate links, including Amazon Associates links. As an Amazon Associate, I earn from qualifying purchases at no extra cost to you.)"}</p>
            </div>

            <div className="marketplace-search-area">
                <TextField
                    placeholder={'Search consoles, controllers, accessories here...'}
                    variant="filled"
                    sx={{
                        width:'100%',
                        color: "#f8f8f8",
                        "& .MuiInputLabel-root": { color: "#f8f8f8" }, // Label color
                        "& .MuiFilledInput-root": {
                          color: "#f8f8f8", // Text color
                          "&::before": { borderBottomColor: "#f8f8f8" }, // Default underline color
                          "&:hover::before": { borderBottomColor: "#f8f8f8" }, // Hover effect
                          "&::after": { borderBottomColor: "#f8f8f8" }, // Focused underline color
                        },
                      }}
                    value={searchText}
                    onChange={(e)=>handleTextChange(e)}
                />
            </div>

            {/* FILTER AREA */}
            <div className="marketplace-filter-area">
                <Button variant='contained' sx={{ backgroundColor:'#ffe74c', color:'#080808', fontFamily:'Paciencia', fontWeight:'600',
                        "&:hover": {
                            backgroundColor: "#FFD700"
                        }
                    }}
                    startIcon={<FilterListIcon sx={{ color:'#191917' }}/>}
                    onClick={()=>handleOpenFilterBox()}
                >
                    Country
                </Button>
            </div>
            {/* FILTER AREA */}

            {/* MAIN PRODUCT LIST AREA */}
            <div className="marketplace-product-list-container">
            {
                searching === true && 
                Array.from(new Array(25)).map((_, index) => (
                    <Card key={index} sx={{ height:'70vh', width:'48.5vh', backgroundColor:'#191917', 
                        boxSizing:'border-box', padding:'1.5%'
                     }}>
                        <Skeleton variant='rectangular' animation='wave' sx={{ height:'50%', width:'100%', backgroundColor:'#2d2d2d',
                            marginBottom:'10px'
                         }}/>
                         <Skeleton height={20} animation='wave' sx={{ backgroundColor:'#2d2d2d' }}/>
                         <Skeleton height={20} animation='wave' sx={{ backgroundColor:'#2d2d2d' }}/>
                         <Skeleton height={20} animation='wave' sx={{ backgroundColor:'#2d2d2d' }}/>
                         <Skeleton height={20} animation='wave' sx={{ backgroundColor:'#2d2d2d' }}/>

                         <Skeleton height={45} width={150} animation='wave' sx={{ backgroundColor:'#2d2d2d' }}/>

                         <Skeleton height={20} width={150} animation='wave' sx={{ backgroundColor:'#2d2d2d' }}/>

                    </Card>
                ))
            }
            {
                searching === false && 
                productList && productList.length > 0 &&
                productList.map((product)=>(
                    <ProductTile key={product.id} productObject={product}/>
                ))
            }    
            </div>
            {/* MAIN PRODUCT LIST AREA */}
        </div>
    )
}