// react
import { useState, useEffect } from 'react';
// database
import { db } from '../../Firebase/firebase';
import { doc, getDoc } from 'firebase/firestore';

export const useFetchProductDetails = ( productID )=>{
    const [fetching, setFetching] = useState(false);
    const [productData, setProductData] = useState(null);
    const [productError, setProductError] = useState(null);

    const fetchProductDetails = async( productURL )=>{
        setFetching(true);

        const productRef = doc(db, "Products", `${productURL}`);
        const productSnap = await getDoc(productRef).catch((prodErr)=>{
            setFetching(false);
            setProductError(prodErr);
            return;
        });

        if(productSnap.exists()){
            const productObject = {
                'id': productSnap.id,
                ...productSnap.data()
            }
            setProductData(productObject);
        }else{
            setFetching(false);
            setProductError('document_does_not_exist');
            return;
        }

        setFetching(false);
        setProductError(null);

    }

    useEffect(()=>{
        if(productID !== null && productID !== undefined){
            fetchProductDetails(productID);
        }
    },[productID])

    return { fetching, productData, productError };
}